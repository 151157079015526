.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Apercu-Regular';
  src: local('Apercu-Regular'), url(./fonts/Apercu-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'PTSans-Regular';
  src: local('PTSans-Regular'), url(./fonts/PTSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto-Regular'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'CrimsonText-Bold';
  src: local('CrimsonText-Bold'), url(./fonts/CrimsonText-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Bold';
  src: local('SourceSansPro-Bold'), url(./fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  src: local('Lato-Bold'), url(./fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Bitter-Medium';
  src: local('Bitter-Medium'), url(./fonts/Bitter-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'LuckiestGuy-Regular';
  src: local('LuckiestGuy-Regular'), url(./fonts/LuckiestGuy-Regular.ttf) format('truetype');
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
 background-color: transparent;
 color: black;
}

.ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
  border-color: black;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0
}

body {
  width: 100%;
  height: 100%;
  background-color: '#a8dba8';
}